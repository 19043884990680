<template>
  <div class="p-6">
    <!-- TABLE CARD -->
    <div class="vx-col w-full">
      <vx-card>
        <div class="vx-card__title mb-5">
          <h4>ประวัติการใช้งาน</h4>
        </div>
        <div class="export-table">
          <!-- DATA TABLE -->
          <vs-table search @search="fetchData" stripe :sst="true" @change-page="handleChangePage"
            :total="total" pagination :noDataText="noDataText" :max-items="perPage" :data="logData">

            <template slot="header">
              <vs-chip color="primary">เริ่มต้น </vs-chip>
                <flat-pickr :config="configDateTimePicker" class="mx-1" v-model="searchQuery.before_datetime"
                  placeholder="โปรดเลือกเวลา" />
                  <vs-chip color="primary"> สิ้นสุด </vs-chip>
                <flat-pickr :config="configDateTimePicker" class="mx-1" v-model="searchQuery.after_datetime"
                  placeholder="โปรดเลือกเวลา" />
            </template>

            <!-- TH -->
            <template slot="thead">
              <vs-th sort-key="datetime"> DATETIME</vs-th>
              <vs-th sort-key="type"> TYPE</vs-th>
              <vs-th sort-key="username"> USERNAME</vs-th>
              <vs-th sort-key="amount"> AMOUNT </vs-th>
              <vs-th sort-key="info"> INFO </vs-th>
              <vs-th sort-key="admin"> ADMIN</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(row, indextr) in data">
                <vs-td style="max-width: 100px;" :data="data[indextr].datetime">
                  <p class="td-data">{{ moment(data[indextr].datetime).tz("Asia/Bangkok") .format("YYYY-MM-DD HH:mm:ss") }} </p>
                </vs-td>
                <vs-td style="max-width: 125px;" :data="data[indextr].type">
                  <p class="td-data">{{ data[indextr].type || '-' }} </p>
                </vs-td>
                <vs-td style="max-width: 100px;" :data="data[indextr].username">
                  <router-link v-if="data[indextr].username" :to="`/member/${data[indextr].username}`">
                    <p class="td-data">{{ data[indextr].username }} </p>
                  </router-link>
                  <p v-else class="td-data"> - </p>
                </vs-td>
                <vs-td style="max-width: 100px;" :data="data[indextr].amount">
                  <p class="td-data">{{ currency(data[indextr].amount) }} </p>
                </vs-td>
                <vs-td style="max-width: 300px;" :data="data[indextr].info">
                  <div class="td-data">
                    <div>
                      <p>{{ data[indextr].info || '-' }}</p>
                    </div>
                    <div v-if="data[indextr].before"><strong>before:</strong> {{ data[indextr].before }}</div>
                    <div v-if="data[indextr].after"><strong>after:</strong> {{ data[indextr].after }}</div>
                  </div>
                </vs-td>
                <vs-td style="max-width: 100px;" :data="data[indextr].admin">
                  <p class="td-data">{{ data[indextr].admin }} </p>
                </vs-td>

              </vs-tr>
            </template>

          </vs-table>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import times from './ReportAff'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import axios from '../../axios'
import moment from 'moment'

export default {
  components: {
    vSelect,
    flatPickr,
    times,
    StatisticsCardLine
  },
  data () {
    return {
      cellAutoWidth: true,
      configDateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:s',
        time_24hr: true
      },
      logData: [],
      searchQuery: {
        before_datetime: moment.tz('Asia/Bangkok').startOf('day').format('YYYY-MM-DD HH:mm'),
        after_datetime: moment.tz('Asia/Bangkok').add(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm'),
        username: null,
        type: null,
        category: null,
        admin: null
      },
      selected: '',
      category: [
        {
          label: 'ประวัติการใช้งาน', value: 'edit'
        },
        {
          label: 'ประวัติการใช้ยอด', value: 'adjustBalance'
        }
      ],
      type: [
        {
          label: 'แก้ไขข้อมูลยูสเซอร์', value: 'edit'
        },
        {
          label: 'ประวัติการใช้ยอด', value: 'adjustBalance'
        }
      ],
      total: 0,
      perPage: 100,
      currentPage: 1,
      noDataText: 'โปรดรอสักครู่ ระบบกำลังดึงข้อมูล...'
    }
  },
  async mounted () {
    await this.fetchFilter()
    await this.fetchData()
  },
  watch: {
    'searchQuery.before_datetime' (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchData()
      }
    },
    'searchQuery.after_datetime' (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchData()
      }
    }
  },
  methods: {
    currency (amount) {
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        return `${(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }
    },
    async fetchFilter () {
      try {

        const response = await axios
          .get('log/filter')

        const { data: responseData } = response

        this.category = responseData.category
        this.type = responseData.type
        return

      } catch (error) {
        console.error(error.message)
      }
    },
    async fetchData (searchString = '') {
      try {
        const pagination = {
          total: this.totals,
          perPage: this.perPage,
          currentPage: this.currentPage
        }

        // Add search string
        this.searchQuery.searchString = searchString

        const params = {
          ...this.searchQuery,
          ...pagination
        }

        const response = await axios
          .get('log/search', {
            params
          })

        const { data: responseData } = response

        if (!(responseData && responseData.status)) {
          this.noDataText = 'ไม่สามารถดึงข้อมูลได้ในขณะนี้'
          return
        }

        if (responseData.data.rows.length <= 0) {
          this.noDataText = 'ไม่พบประวัติการใช้งาน'
        }

        this.logData = responseData.data.rows
        this.total = responseData.data.total
        this.perPage = responseData.data.perPage
        this.currentPage = responseData.data.currentPage

        return

      } catch (error) {
        console.error(error.message)
      }
    },
    async clearAndFetchData () {
      this.searchQuery = {
        before_datetime: moment.tz('Asia/Bangkok').startOf('day').format('YYYY-MM-DD HH:mm'),
        after_datetime: moment.tz('Asia/Bangkok').add(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm'),
        username: null,
        type: null,
        category: null,
        admin: null
      }

      await this.fetchData(1)
    },
    async handleChangePage (page) {
      this.currentPage = page

      const self = this
      self.logData = []
      self.noDataText = 'โปรดรอสักครู่ ระบบกำลังดึงข้อมูล...'

      await this.fetchData(page)
    }
  }
}


</script>

<style scoped>
.td-data {
  white-space: pre-line;
  overflow: hidden;
}
</style>
